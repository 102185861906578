'use client';
import { Close } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Slide,
	styled,
	Tooltip,
	tooltipClasses,
	TooltipProps,
	Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
	TRACKING_STATUS_WHOLESALE,
	TRACKING_STATUS,
	useClientTranslation,
} from '@negotium/common';
import { IStatusTrace } from '@negotium/models';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
	Completed,
	Delivered,
	Paid,
	Processing,
	ReadyDeliver,
	Sending,
	Validated,
} from '../Svgs';
import PendingIcon from '@mui/icons-material/Pending';
import ThemeBasic from 'apps/customer-portal/utils/theme';
const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.white?.light,
		maxWidth: 220,
		borderRadius: 5,
	},
}));

type TrackingModalProps = {
	open: boolean;
	isWholesaler: boolean;
	onClose: () => void;
	info: Array<IStatusTrace>;
	lng: 'es' | 'en';
	active: string;
};

export const TrackingModal = ({
	open,
	onClose,
	info,
	lng,
	active,
	isWholesaler,
}: TrackingModalProps) => {
	const { t } = useClientTranslation(lng, ['orders', 'common']);
	const [activeStatus, setActiveStatus] = useState<string>('paid');
	const [showDetails, setShowDetails] = useState<{
		label: { en: string; es: string };
		value: string;
		description: { en: string; es: string };
	}>({
		label: { en: 'Paid', es: 'Pagado' },
		description: {
			en: 'The order has been paid',
			es: 'El pedido ha sido pagado',
		},
		value: 'paid',
	});

	const STATUS_LIST = isWholesaler
		? TRACKING_STATUS_WHOLESALE
		: TRACKING_STATUS;

	useEffect(() => {
		if (active && open) {
			setActiveStatus(active);
		}
	}, [active, open]);

	useEffect(() => {
		if (activeStatus) {
			const status = STATUS_LIST.find((item) => item.value === activeStatus);
			if (status) {
				setShowDetails(status);
			}
		}
	}, [activeStatus]);

	function checkStatusActive(status: string) {
		return info.some((item) => item.status === status);
	}
	function getInfoStatusActive(status: string) {
		return info.find((item) => item.status === status);
	}

	function renderIcon(state: string, active: boolean) {
		switch (state) {
			case 'pending':
				return (
					<PendingIcon
						sx={{
							color:
								ThemeBasic.palette[active ? 'white' : 'secondary']?.main || '',
						}}
						fontSize='medium'
					/>
				);
			case 'paid':
				return (
					<Paid
						color={
							ThemeBasic.palette[active ? 'white' : 'secondary']?.main || ''
						}
					/>
				);
			case 'validated':
				return (
					<Validated
						color={
							ThemeBasic.palette[active ? 'white' : 'secondary']?.main || ''
						}
					/>
				);
			case 'processing':
				return (
					<Processing
						color={
							ThemeBasic.palette[active ? 'white' : 'secondary']?.main || ''
						}
					/>
				);
			case 'shipping':
				return (
					<Sending
						color={
							ThemeBasic.palette[active ? 'white' : 'secondary']?.main || ''
						}
					/>
				);
			case 'ready':
				return (
					<ReadyDeliver
						color={
							ThemeBasic.palette[active ? 'white' : 'secondary']?.main || ''
						}
					/>
				);
			case 'delivered':
				return (
					<Delivered
						color={
							ThemeBasic.palette[active ? 'white' : 'secondary']?.main || ''
						}
					/>
				);
			default:
				return (
					<Completed
						color={
							ThemeBasic.palette[active ? 'white' : 'secondary']?.main || ''
						}
					/>
				);
		}
	}

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			fullWidth
			maxWidth='md'
			onClose={onClose}
			aria-describedby='alert-dialog-slide-description'
			PaperProps={{
				sx: {
					backgroundColor: (theme) => theme.palette.white?.main || 'white',
					py: 2,
					borderRadius: 3,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						background: (theme) => theme.palette.secondary.light,
						backdropFilter: 'blur(10px)',
					},
				},
			}}
		>
			<DialogTitle>
				<Box
					display='flex'
					justifyContent='space-between'
					sx={{
						px: 3,
					}}
				>
					<Typography variant='TitleSection' fontSize='clamp(1rem,3vw,1.2rem)'>
						{t('tracking')}
					</Typography>
					<Button
						onClick={() => onClose()}
						variant='text'
						color='secondary'
						sx={{
							p: 0,
							minWidth: '30px',
							height: '30px',
							borderRadius: '50%',
						}}
					>
						<Close />
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item md={12} xs={6}>
						<Box
							my={{ md: 3 }}
							display='flex'
							justifyContent='space-between'
							alignItems={{ md: 'center', xs: 'flex-start' }}
							flexDirection={{
								md: 'row',
								xs: 'column',
							}}
							sx={{
								px: 2,
								position: 'relative',
							}}
						>
							{STATUS_LIST.map((state, index) => {
								return (
									<HtmlTooltip
										disableHoverListener={
											getInfoStatusActive(state.value)?.date ? false : true
										}
										key={state.value}
										placement='top'
										title={
											<Typography
												variant='ExplicationTextBold'
												fontWeight='500'
												fontSize='13px'
											>
												{getInfoStatusActive(state.value)?.date
													? dayjs(
															getInfoStatusActive(state.value)?.date
													  ).format('DD/MM/YYYY hh:mm a')
													: ''}
											</Typography>
										}
									>
										<Box
											sx={{
												display: 'flex',
												flexDirection: { md: 'column', xs: 'row' },
												alignItems: 'center',
												position: 'relative',
												height: { md: '120px', xs: '100px' },
												justifyContent: {
													md: 'space-between',
													xs: 'flex-start',
												},
												width: {
													md: `calc(100% / ${STATUS_LIST.length})`,
													xs: '100%',
												},
												marginTop: {
													xs: 2,
													md: 0,
												},
												...(index !== STATUS_LIST.length - 1 && {
													'&::after': {
														content: '""',
														display: 'block',
														width: { md: '100%', xs: '3px' },
														height: { md: '3px', xs: '100%' },
														backgroundColor: checkStatusActive(state.value)
															? (theme) => theme.palette.secondary.main || ''
															: (theme) => theme.palette.white?.light || '',

														position: 'absolute',
														left: { md: '50%', xs: '21.4px' },
														zIndex: 0,
														top: { md: '21.4px', xs: '50%' },
													},
												}),
											}}
										>
											<Box
												sx={{
													zIndex: 2,
													width: '45px',
													height: '45px',
													transform: 'rotate(45deg)',
													backgroundColor: checkStatusActive(state.value)
														? (theme) => theme.palette.secondary.main || ''
														: 'rgba(217,217,217,1)',

													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													cursor: 'default',
													borderRadius: 1,
													boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
												}}
											>
												<Box
													sx={{
														transform: 'rotate(-45deg)',
														mt: '3px',
														ml: '3px',
													}}
												>
													{renderIcon(
														state.value,
														checkStatusActive(state.value)
													)}
												</Box>
											</Box>
											<Typography
												variant='ExplicationTextBold'
												mt={{ md: 2 }}
												ml={{ xs: 2, md: 0 }}
												textAlign='center'
												textTransform='uppercase'
												fontSize='12px'
												color={
													checkStatusActive(state.value)
														? 'secondary.main'
														: 'white.light'
												}
											>
												{state.label[lng]}
											</Typography>
										</Box>
									</HtmlTooltip>
								);
							})}
						</Box>
					</Grid>
					<Grid item xs={1} md={12}>
						<Divider
							sx={{
								mt: { md: 2 },
								mb: { md: 3 },
								borderWidth: 1,
								borderColor: (theme) => theme.palette.secondary.main,
								width: { xs: '2px', md: 'unset' },
								background: (theme) => theme.palette.secondary.main,
								height: { xs: '100%', md: 'auto' },
							}}
						/>
					</Grid>
					<Grid item xs={4} md={12}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								justifyContent: 'center',
								px: { md: 4 },
							}}
						>
							<Typography
								variant='ExplicationTextBold'
								fontSize='clamp(1rem,3vw,1.2rem)'
								mb={1}
							>
								{showDetails.label[lng]}
							</Typography>
							<Typography
								variant='ExplicationText'
								fontSize='1rem'
								color='secondary.main'
							>
								{showDetails.description[lng]}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={1} md={12}>
						<Divider
							sx={{
								mt: { md: 2 },
								mb: { md: 3 },
								borderWidth: 1,
								borderColor: (theme) => theme.palette.secondary.main,
								width: { xs: '2px', md: 'unset' },
								background: (theme) => theme.palette.secondary.main,
								height: { xs: '100%', md: 'auto' },
							}}
						/>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
